<template>
  <div class="px-10">
    <div class="text-h4 primary--text">หมวดหมู่รายจ่าย</div>

    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      <div style="width: 200px;">
        <v-select
          label="หมวดหมู่หลัก"
          :items="category_list"
          v-model="filter.category_id"
          item-value="value"
          item-text="text"
          outlined
          dense
          hide-details
          @change="onCategoryChange"
        />
      </div>

      <v-spacer></v-spacer>

      <div style="width: 200px;">
        <v-autocomplete
          label="หมวดหมู่ย่อย"
          :items="sub_category_list"
          v-model="filter.sub_category_id"
          item-value="_id"
          item-text="name"
          outlined
          dense
          hide-details
          @change="onSubCategoryChange"
        >
        </v-autocomplete>
      </div>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn color="primary" small class="ml-5" @click="add()">
        <v-icon left small>mdi-plus</v-icon> เพิ่มหมวดหมู่
      </v-btn>
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          list
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            filter.item_per_page * (filter.page - 1)
        }}
      </template>

      <template v-slot:[`item.category_id`]="{ item }">
        {{ item.category_id == 'PC1' ? 'พรบ.' : '' }}
        {{ item.category_id == 'PC2' ? 'ประกันภัย' : '' }}
        {{ item.category_id == 'PC3' ? 'ขนส่ง' : '' }}
        {{ item.category_id == 'PC4' ? 'ทั่วไป' : '' }}
        {{ item.category_id == 'PC5' ? 'ค่าใช้จ่ายบริษัท' : '' }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="90%" v-model="dialogUpdate">
      <Detail
        :id="expense_category_id"
        @onClose="dialogUpdate = false"
        @onSave="
          () => {
            dialogUpdate = false;
            getData();
          }
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import Detail from '@/views/pages/expenses/expensegroup/Detail.vue';

export default {
  components: {
    Detail
  },
  data: () => ({
    search: '',
    loading: true,
    pageCount: 0,

    headers: [
      { text: 'ลำดับ', value: 'index', align: 'center' },
      { text: 'หมวดหมู่หลัก', value: 'category_id', filterable: false },
      { text: 'หมวดหมู่ย่อย', value: 'name' },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],
    list: [],

    dialogDetail: false,
    dialogUpdate: false,
    dialogDetailUpdate: true,

    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
      category_id: '',
      sub_category_id: ''
    },

    category_list: [
      { text: 'ทั้งหมด', value: '' },
      { text: 'พรบ.', value: 'PC1' },
      { text: 'ประกันภัย', value: 'PC2' },
      { text: 'ขนส่ง', value: 'PC3' },
      { text: 'ทั่วไป', value: 'PC4' },
      { text: 'ค่าใช้จ่ายบริษัท', value: 'PC5' },
    ],
    sub_category_list: [],

    expense_category_id: ''
  }),

  async created() {
    this.loading = true;
    this.getSubCategory();
    this.getData();
  },

  methods: {
    add() {
      this.expense_category_id = '';
      this.dialogUpdate = true;
    },
    edit(item) {
      this.expense_category_id = item._id;
      this.dialogUpdate = true;
    },
    async onCategoryChange() {
      this.getSubCategory();
      this.filter.sub_category_id = '';
      this.getData();
    },
    async onSubCategoryChange() {
      this.getData();
    },
    async getSubCategory() {
      let filter = {
        category_id: this.filter.category_id
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense_category/get_sub_category_list`, body)
        .then(async res => {
          this.sub_category_list = [].concat(
            [{ _id: '', name: 'ทั้งหมด' }],
            res.result
          );
        });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { expense_category_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };

            await this.$axios
              .post(
                `${this.$baseUrl}/expense_category/delete_expense_category`,
                body
              )
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense_category/get_expense_category`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
